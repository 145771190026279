import type { NextPage } from "next";
import { Layout } from "components/Layout";
import Link from "next/link";
import Image from "next/image";
import { Logo } from "components/Logo";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { getImageSrc } from "utils/getImageSrc";
import {
  BsSafe2,
  BsFillLightningFill,
  BsFillCalculatorFill,
  BsTools,
} from "react-icons/bs";
import { getAppUrl, getProjectUrl } from "utils/urls";
import hero from "assets/hero.png";

const Home: NextPage = () => {
  return (
    <Layout>
      <div className="absolute right-0 top-0 left-0 bottom-0 flex flex-col">
        <div className="bg-neutral py-2">
          <div className="navbar mx-auto max-w-6xl">
            <div className="flex-1">
              <Link href="/">
                <a>
                  <Logo />
                </a>
              </Link>
            </div>
            <div className="navbar-end">
              <Link href={getAppUrl("/me")}>
                <button className="btn btn-secondary ">
                  <label>
                    <svg
                      width="16px"
                      height="16px"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="bi bi-stack"
                    >
                      <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.598.598 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.598.598 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.598.598 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535L7.733.063z" />
                      <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.598.598 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.659z" />
                    </svg>
                  </label>
                  <div className="ml-2">My Staking</div>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-base-200 py-12 px-4">
          <div className="mx-auto flex max-w-6xl flex-col justify-between md:flex-row md:items-center">
            <div className="order-2 max-w-xl md:order-1 md:pr-6">
              <h1 className="text-5xl text-gray-100">
                Three words,{" "}
                <span className="font-semibold">Solana NFT staking</span>. One
                price, <span className="font-semibold">free</span>.
              </h1>
              <div className="mt-4 text-xl text-gray-400">
                Have 10 minutes? That is about all you will need to get a fully
                functioning staking website up & running for your project.
              </div>
              <div className="mt-10">
                <Link href={getAppUrl("/submit")} passHref>
                  <a className="btn btn-primary btn-lg">Set up staking now</a>
                </Link>
              </div>
            </div>
            <div className="order-1 mb-4 max-w-[90px] md:order-2 md:mb-0 md:max-w-none">
              <div className="home-animation">
                <Image
                  src={hero}
                  alt="FloppyLabs"
                  placeholder="blur"
                  className="rounded shadow"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="px-4">
          <div className="md:py-18 mx-auto grid max-w-6xl grid-cols-1 gap-12 py-12 md:grid-cols-4">
            <div>
              <div className="mb-4">
                <BsSafe2 size="2em" />
              </div>
              <h3 className="text-xl font-medium">Escrow staking</h3>
              <div className="mt-4 text-neutral-content text-gray-400">
                Fully on-chain solution. Staked NFTs secured in the escrow
                wallet. Reward stakers with your own token or native SOL.
              </div>
            </div>
            <div>
              <div className="mb-4">
                <BsFillLightningFill size="2em" />
              </div>
              <h3 className="text-xl font-medium">Self-service</h3>
              <div className="mt-4 text-neutral-content text-gray-400">
                No need to contact the team. Follow a simple wizard and get your
                staking website up and running in less than 10 minutes.
              </div>
            </div>
            <div>
              <div className="mb-4">
                <BsFillCalculatorFill size="2em" />
              </div>
              <h3 className="text-xl font-medium">Free, forever</h3>
              <div className="mt-4 text-neutral-content text-gray-400">
                The service is completely free to the project. We charge a small
                transaction fee (0.01 SOL) to stakers for stake & unstake.
              </div>
            </div>
            <div>
              <div className="mb-4">
                <BsTools size="2em" />
              </div>
              <h3 className="text-xl font-medium">Creator tools</h3>
              <div className="mt-4 text-neutral-content text-gray-400">
                Staking analytics, customization, holder snapshot, token
                management, configuration updates, and more.
              </div>
            </div>
          </div>
        </div>

        <div className="px-4">
          <h1 className="mx-auto max-w-6xl pt-12 text-center text-4xl md:p-10">
            80,000+ NFTs, from 250+ collections, already staked!
          </h1>
        </div>

        <div className="px-4">
          <h2 className="md:pt-18 mx-auto max-w-6xl pt-12 text-2xl">
            Popular staking projects
          </h2>
          <div className="mx-auto grid max-w-6xl grid-cols-1 gap-12 py-8 md:grid-cols-4 md:py-8">
            <Link href="https://staking.radrugs.io/">
              <a>
                <div className="shadow-l card card-compact bg-neutral">
                  <figure>
                    <img
                      src="https://raw.githubusercontent.com/floppy-labs/public-images/master/radrugs_logo.png"
                      width={250}
                      height={250}
                      alt="RadRugs"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">RadRugs</h2>
                    <p>
                      Multi-chain #NFT app to help you #DYOR. Stake your RadRugs
                      NFT and get $SAFE
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href={getProjectUrl("narentines")}>
              <a>
                <div className="shadow-l card-compact card bg-neutral">
                  <figure>
                    <img
                      src="https://wtogtjeudveydyq7zhelsgm2ako2bj3vwxowkidw6gvolpdv.arweave.net/tNxppJQdSYHi-H8nIuRma-Ap2gp3W13WUgdvGq5bx1s"
                      width={250}
                      height={250}
                      alt="Narentines"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">Narentines</h2>
                    <p>
                      The Narentines is a collection of 3333 randomly generated
                      NFTs living on the Solana blockchain
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href="https://staking.fellowshipdao.com/">
              <a>
                <div className="shadow-l card-compact card bg-neutral">
                  <figure>
                    <img
                      src="https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://www.arweave.net/ppbE5XK172cmdCFf4R6ckIlW_zHlB4xRcYm-TEdxucE?ext=png"
                      width={250}
                      height={250}
                      alt="Fellowship DAO"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">Fellowship DAO</h2>
                    <p>
                      The Fellowship is a community of creators, artists,
                      developers, and Solana enthusiasts
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href="https://stoned-ninjas.floppylabs.io">
              <a>
                <div className="shadow-l card-compact card bg-neutral">
                  <figure>
                    <img
                      src="https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://bafybeifltegp4knbmyubhuxo3styoi77xl7q5vpls5sk374ropsei5kf74.ipfs.dweb.link/3958.png"
                      width={250}
                      height={250}
                      alt="Oni Void"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">Stoned Ninjas</h2>
                    <p>
                      4200 Stoned Ninjas from The Ninjas Collection. Daily
                      rewards of $KUNAI utility token
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href={getProjectUrl("braindead")}>
              <a>
                <div className="shadow-l card-compact card bg-neutral">
                  <figure>
                    <img
                      src="https://bafybeidlrhld43y5pm5e6mcdilyghetqip43gw467jsdicapfg2g545ram.ipfs.nftstorage.link/2204.jpeg"
                      width={250}
                      height={250}
                      alt="BrainDead"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">BrainDead</h2>
                    <p>
                      Brainless people need to die in order to resurrect and
                      start their braindead life
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href={getProjectUrl("angrydao")}>
              <a>
                <div className="shadow-l card-compact card bg-neutral">
                  <figure>
                    <img
                      src="https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://www.arweave.net/67JyuVofgGlisi1B0WXmjHAyg_Z8-IYhJ0XY9xiiMzk?ext=png"
                      width={250}
                      height={250}
                      alt="Angry DAO"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">Angry DAO</h2>
                    <p>
                      Game-FI DAO and P2E Games. Earn $ANGRY. #NEAR & #Solana.
                      Ascend with the Key
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href={getProjectUrl("apexstake")}>
              <a>
                <div className="card-compact shadow-l card bg-neutral">
                  <figure>
                    <img
                      src="https://3r3q234ltmrydhxtexznrzk2qylbyohc4mg3lfvqfqaw5nor44.arweave.net/3HcNb4ubI4Ge8yXy2OVahhYcO-OLjDbWWsCwBbrXR54?ext=png"
                      width={250}
                      height={250}
                      alt="ApeX Club"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">ApeX Club</h2>
                    <p>
                      Staking will be used to earn $APX coins. Each staked Ape
                      will earn 1 $APX in every 24 hours
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href={getProjectUrl("blockchain-ballers-nft")}>
              <a>
                <div className="card-compact shadow-l card bg-neutral">
                  <figure>
                    <img
                      src="https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://ipfs.io/ipfs/bafybeiao7szpcwbn42vwu2w5hmlmth5gc2edjyjwnp4axjx7p53wb6bskm/217.png"
                      width={250}
                      height={250}
                      alt="Blockchain Ballers NFT"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">Blockchain Ballers</h2>
                    <p>
                      They’re an ASIC mining backed NFT with a core of
                      degenerative soul art
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href={getProjectUrl("solana-faction-cards")}>
              <a>
                <div className="shadow-l card-compact card bg-neutral">
                  <figure>
                    <img
                      src="https://raw.githubusercontent.com/faction-cards/assets/main/images/coins/SFC-PRESALE/logo.png"
                      width={250}
                      height={250}
                      alt="Solana Faction Cards"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">Solana Faction Cards</h2>
                    <p>
                      Solana Faction Cards enables staking, governance and
                      cross-chain multiverse
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href={getProjectUrl("blxck-flip")}>
              <a>
                <div className="shadow-l card-compact card bg-neutral">
                  <figure>
                    <img
                      src="https://pbs.twimg.com/media/FYCREtNX0AQZ4xz?format=jpg&name=medium"
                      width={250}
                      height={250}
                      alt="BLXCK-FLIP"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">BLXCK-FLIP</h2>
                    <p>
                      BLXCK-FLIP are 5555 generated art NFTs directly relating
                      to mainstream media events
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href={getProjectUrl("bubba-chimp-crew")}>
              <a>
                <div className="card-compact shadow-l card bg-neutral">
                  <figure>
                    <img
                      src="https://raw.githubusercontent.com/floppy-labs/public-images/master/bubba-chimp-crew.png"
                      width={250}
                      height={250}
                      alt="Bubba Chimp Crew"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">Bubba chimp crew</h2>
                    <p>
                      This is the staking page for the awesome Bubba Chimp Crew!
                      Bubba Bubba ♥
                    </p>
                  </div>
                </div>
              </a>
            </Link>
            <Link href={getProjectUrl("arctic-frenz-arctic-walrus")}>
              <a>
                <div className="card-compact shadow-l card bg-neutral">
                  <figure>
                    <img
                      src="https://fjlewvdffveyhssmoqj5m3ub6qwidcz5msvl3x5fygsqcgi.arweave.net/KlZLVGUtSYP_KTHQT1m6B9CyBiz1kqr-3f_p-cGlARk?ext=png"
                      width={250}
                      height={250}
                      alt="Arctic Walrus"
                    />
                  </figure>
                  <div className="card-body">
                    <h2 className="card-title">Arctic Frenz</h2>
                    <p>
                      An exclusive family, 69 NFTs per collection. Liquidity
                      added to a Raydium LP for rewarding
                    </p>
                  </div>
                </div>
              </a>
            </Link>
          </div>
        </div>

        <div className="bg-base-200 py-12 px-4">
          <div className="mx-auto flex max-w-2xl flex-col items-center text-xl leading-8 text-gray-400">
            <div className="mb-6 text-center">
              FloppyLabs is a{" "}
              <span className="font-medium">
                {" "}
                revenue sharing staking-as-a-service platform{" "}
              </span>{" "}
              focused on quality, speed, and self-service. Free and painless
              set-up for the project owner. Revenue stream generated through
              small transaction fees will be shared with FloppyHeads NFTs
              holders, minting in April 2022.
            </div>
            <div>
              <a
                className="btn btn-outline btn-primary mr-2"
                href="https://discord.gg/vPGn2vfXJt"
                target="_blank"
                rel="noreferrer"
              >
                Join our Discord
              </a>

              <a
                className="btn btn-outline btn-primary ml-2"
                href="https://twitter.com/FloppyLabs"
                target="_blank"
                rel="noreferrer"
              >
                Follow us on Twitter
              </a>
            </div>
          </div>
        </div>

        <div className="flex-1 bg-neutral py-8">
          <div className="mx-auto flex max-w-6xl flex-col items-center">
            <div>
              <Logo />
            </div>
            <div className="mt-6 grid grid-cols-2 gap-6">
              <a
                href="https://discord.gg/vPGn2vfXJt"
                target="_blank"
                rel="noreferrer"
              >
                <FaDiscord size="1.4em" />
              </a>
              <a
                href="https://twitter.com/FloppyLabs"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size="1.4em" />
              </a>
            </div>
            <div className="mt-4">
              <a
                href="mailto:hello@floppylabs.io"
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                hello@floppylabs.io
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
